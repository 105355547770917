import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import backgroundImg1 from "../assets/bg1.jpg"; // Ensure these paths are correct
import backgroundImg2 from "../assets/bg2.jpg";
import backgroundImg3 from "../assets/corpoarte3.jpg";

const HeroSection = () => {
  return (
    <>
      {/* Import Archivo Black, Archivo, and Poppins Fonts */}
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:wght@400;700&family=Poppins:wght@300;400;500;600&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-archivo {
            font-family: 'Archivo', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
          /* Hide default Swiper navigation arrows */
          .swiper-button-next,
          .swiper-button-prev {
            display: none !important;
          }
        `}
      </style>

      <section className="relative pt-4 pb-0 flex items-center justify-center w-full h-[80vh] sm:h-[60vh] lg:h-[80vh]">
        {/* Adjusted section for full coverage */}
        <div className="absolute top-0 left-0 w-full h-full">
          {/* Absolute positioned for full coverage */}
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            className="w-full h-full"
          >
            {/* Slide 1 */}
            <SwiperSlide>
              <div className="relative w-full h-full">
                <img
                  src={backgroundImg1}
                  alt="Slide 1"
                  className="w-full h-full object-cover" // Ensure image covers full area
                />
                <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
                  {/* Centering text */}
                  <div className="p-4 text-center max-w-[100%] md:max-w-[100%] lg:max-w-[100%]">
                    <h1 className="text-xl md:text-3xl lg:text-3xl text-white font-archivo-black">
                      YOUR RECRUITMENT CHANNEL
                    </h1>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            {/* Slide 2 */}
            <SwiperSlide>
              <div className="relative w-full h-full">
                <img
                  src={backgroundImg2}
                  alt="Slide 2"
                  className="w-full h-full object-cover" // Ensure image covers full area
                />
                <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
                  {/* Centering text */}
                  <div className="p-4 text-center max-w-[100%] md:max-w-[100%] lg:max-w-[100%]">
                    <h1 className="text-xl md:text-3xl lg:text-3xl text-white font-archivo-black">
                      We connect talent to your vision, bridging you with world-class professionals for strategic growth.
                    </h1>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            {/* Slide 3 */}
            <SwiperSlide>
              <div className="relative w-full h-full">
                <img
                  src={backgroundImg3}
                  alt="Slide 3"
                  className="w-full h-full object-cover" // Ensure image covers full area
                />
                <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
                  {/* Centering text */}
                  <div className="p-4 text-center max-w-[100%] md:max-w-[100%] lg:max-w-[100%]">
                    <h1 className="text-xl md:text-3xl lg:text-3xl text-white font-archivo-black">
                      A proven decade of excellence in delivering global talent solutions.
                    </h1>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
