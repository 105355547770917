import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/LOGO1 (2).png'; // Make sure the path is correct
import './Header.css';
import CVForm from './Cvform'; // Import the CVForm component

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isContactPanelOpen, setIsContactPanelOpen] = useState(false);
  const [isCVFormOpen, setIsCVFormOpen] = useState(false); // State to control the CV form visibility

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleContactPanel = () => {
    setIsContactPanelOpen(!isContactPanelOpen);
  };

  const closeContactPanel = () => {
    setIsContactPanelOpen(false);
  };

  const openCVForm = () => {
    setIsCVFormOpen(true); // Open the CV form when the button is clicked
  };

  const closeCVForm = () => {
    setIsCVFormOpen(false); // Close the CV form
  };

  return (
    <header className="bg-white shadow-md font-poppins relative">
      {/* Ensure Poppins font is imported */}
      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
        .font-poppins {
          font-family: 'Poppins', sans-serif;
        }
      `}</style>

      <div className="container mx-auto px-4 py-10 flex justify-between items-center"> {/* Reduced padding */}
        {/* Logo - Move to the left */}
        <div className="flex items-center justify-start -ml-0 md:ml-2"> {/* Adjusted margins for mobile and desktop */}
          <NavLink to="/" className="flex-shrink-0">
            <img src={logo} alt="logo" className="h-8 w-auto md:h-10" /> {/* Adjusted logo size for mobile and desktop */}
          </NavLink>
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-4 items-center ml-auto">
          <NavLink
            to="/"
            onClick={closeMenu}
            className="text-customGray font-bold text-md uppercase flex items-center hover:underline hover:underline-offset-4 hover:text-customY"
          >
            Home
          </NavLink>
          <NavLink
            to="/aboutUs"
            onClick={closeMenu}
            className="text-customGray font-bold text-md uppercase flex items-center hover:underline hover:underline-offset-4 hover:text-customY"
          >
            About
          </NavLink>
          <NavLink
            to="/Services"
            onClick={closeMenu}
            className="text-customGray font-bold text-md uppercase flex items-center hover:underline hover:underline-offset-4 hover:text-customY"
          >
            Services
          </NavLink>
          <NavLink
            to="/aviation"
            onClick={closeMenu}
            className="text-customGray font-bold text-md uppercase flex items-center hover:underline hover:underline-offset-4 hover:text-customY"
          >
            Aviation
          </NavLink>
          <NavLink
            to="/industry"
            onClick={closeMenu}
            className="text-customGray font-bold text-md uppercase flex items-center hover:underline hover:underline-offset-4 hover:text-customY"
          >
            Industry Practices
          </NavLink>

          

          <NavLink
            to="/contact"
            onClick={closeMenu}
            className="text-customGray font-bold text-md uppercase flex items-center hover:underline hover:underline-offset-4 hover:text-customY"
          >
            Contact
          </NavLink>
        </nav>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-customY focus:outline-none">
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      {isMenuOpen && (
        <div className="md:hidden absolute w-full bg-white shadow-md mt-2 z-50">
          <nav className="flex flex-col items-start space-y-1 p-4 font-poppins">
            <NavLink
              to="/"
              onClick={closeMenu}
              className="text-black text-base font-medium flex justify-between w-full py-2 border-b border-gray-300"
            >
              Home <span className="ml-1">&#9662;</span>
            </NavLink>
 <NavLink
              to="/aboutUs"
              onClick={closeMenu}
              className="text-black text-base font-medium flex justify-between w-full py-2 border-b border-gray-300"
            >
              About <span className="ml-1">&#9662;</span>
            </NavLink>
            <NavLink
              to="/Services"
              onClick={closeMenu}
              className="text-black text-base font-medium flex justify-between w-full py-2 border-b border-gray-300"
            >
              Services <span className="ml-1">&#9662;</span>
            </NavLink>

            <NavLink
              to="/aviation"
              onClick={closeMenu}
              className="text-black text-base font-medium flex justify-between w-full py-2 border-b border-gray-300"
            >
              Aviation <span className="ml-1">&#9662;</span>
            </NavLink>

            <NavLink
              to="/Industry"
              onClick={closeMenu}
              className="text-black text-base font-medium flex justify-between w-full py-2 border-b border-gray-300"
            >
              Industry Practices <span className="ml-1">&#9662;</span>
            </NavLink>
            <NavLink
              to="/aboutUs"
              onClick={closeMenu}
              className="text-black text-base font-medium flex justify-between w-full py-2 border-b border-gray-300"
            >
              About <span className="ml-1">&#9662;</span>
            </NavLink>
            <NavLink
              to="/contact"
              onClick={closeMenu}
              className="text-black text-base font-medium flex justify-between w-full py-2 border-b border-gray-300"
            >
              Contact <span className="ml-1">&#9662;</span>
            </NavLink>
          </nav>
        </div>
      )}

      {/* Contact Us Button */}
      <button
        onClick={toggleContactPanel}
        className="fixed bottom-8 right-8 bg-black text-white rounded-full p-4 shadow-lg focus:outline-none z-50"
      >
        Contact Us
      </button>

      {/* Contact Us Slide-Out Panel */}
      <div
        className={`fixed top-0 right-0 w-80 h-full bg-white shadow-lg transform transition-transform duration-300 z-50 text-customGray ${
          isContactPanelOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="p-4 flex justify-between items-center border-b border-customGray">
          <h2 className="text-lg font-semibold">Contact Us</h2>
          <button onClick={closeContactPanel} className="text-customGray text-2xl font-semibold">
            &times;
          </button>
        </div>
        <div className="p-4">
          <h3 className="text-md font-bold mb-2">Corporates</h3>
          <p className="mb-2">
            Looking to hire top talent? We connect businesses with premium candidates across industries. Let’s build your winning team together.
          </p>
          
          <p>
            Email: 
            <a href="mailto:shraddha@onboardhrservices.com" className="text-customY underline ml-1 text-sm">
            Jobs@onboardhrservices.com
            </a>
          </p>
          <hr className="my-4 border-customGray" />
          <h3 className="text-md font-bold mb-2">Candidates</h3>
          <p>Are you ready to take the next big step in your career?</p>
          <button onClick={openCVForm} className="bg-customY text-white px-4 py-2 mt-2 focus:outline-none">
            Submit Your CV
          </button>
        </div>
      </div>

      {/* CV Form Panel */}
      {isCVFormOpen && <CVForm closeForm={closeCVForm} />}
    </header>
  );
};

export default Header;
