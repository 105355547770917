import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa'; // Importing Icons

const Footer = () => {
  return (
    <footer className="bg-customGray text-white py-12 font-poppins">
      <div className="container mx-auto px-4 md:px-12 lg:px-16">
        
        {/* Navigation Links */}
        <div className="flex flex-col md:flex-row justify-between space-y-6 md:space-y-0 md:space-x-12 mb-8">
          
          {/* Links Section */}
          <div className="flex flex-col items-center md:items-start">
            <a href="#" className="text-white hover:text-gray-400 uppercase font-bold text-base md:text-lg mb-2">Home</a>
            <a href="/Services" className="text-white hover:text-gray-400 uppercase font-bold text-base md:text-lg mb-2">Services</a>
            <a href="/aviation" className="text-white hover:text-gray-400 uppercase font-bold text-base md:text-lg mb-2">Aviation</a>
            <a href="Industry" className="text-white hover:text-gray-400 uppercase font-bold text-base md:text-lg mb-2">Industry Practices</a>
            <a href="AboutUs" className="text-white hover:text-gray-400 uppercase font-bold text-base md:text-lg mb-2">About</a>
            <a href="Contact" className="text-white hover:text-gray-400 uppercase font-bold text-base md:text-lg">Contact</a>
          </div>

          {/* Contact Section */}
          <div className="text-center md:text-left">
            <h4 className="text-lg md:text-xl font-semibold text-white mb-4">CONTACT US</h4>
            
            <p className="text-gray-400 flex items-center justify-center md:justify-start">
              <FaEnvelope className="mr-2" /> Jobs@onboardhrservices.com
            </p>

            
          </div>

          {/* Social Icons */}
          <div className="flex justify-center md:justify-start space-x-6">
            <a href="https://www.facebook.com/HiringForAspiringAviationProfessionels/about/" className="text-white hover:text-gray-400"><FaFacebookF /></a>
            <a href="#" className="text-white hover:text-gray-400"><FaLinkedinIn /></a>
            <a href="https://www.instagram.com/onboard_hrservices_mumbai/?hl=en" className="text-white hover:text-gray-400"><FaInstagram /></a>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="border-t border-gray-700 pt-6 text-center">
          <p className="text-gray-400 text-sm">
            &copy; 2024 Onboard HRServices. All rights reserved. 
            <a href="#" className="text-gray-400 hover:text-gray-200 ml-1">Powered by The Work Journals</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
