import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo1 from "../assets/Qatar_Airways-.png";
import logo2 from "../assets/AirIndia.png";
import logo3 from "../assets/logo-dhl.png";
import logo4 from "../assets/IndiGo-Logo.png";
import logo5 from "../assets/Thomas cook.png";
import logo6 from "../assets/tata.png";
import logo7 from "../assets/Indus.png";
import logo8 from "../assets/dmart.webp";
import logo9 from "../assets/TechManindra.avif";
import logo10 from "../assets/uflex.png";
import logo11 from "../assets/kama.png";
import logo12 from "../assets/Tatacapital.png";
import logo13 from "../assets/shiram.jpg";
import logo14 from "../assets/roha.jpg";
import logo15 from "../assets/guardian.png";
import logo16 from "../assets/teleperformance.png";
import logo17 from "../assets/beyondesign.png";
import logo18 from "../assets/religare.jpg";
import logo19 from "../assets/pnb.jpg";
import logo20 from "../assets/vista.JPG";

const clients = [
  { name: "Qatar Airways", imgSrc: logo1 },
  { name: "Air India", imgSrc: logo2 },
  { name: "DHL", imgSrc: logo3 },
  { name: "IndiGo", imgSrc: logo4 },
  { name: "Thomas Cook", imgSrc: logo5 },
  { name: "Tata", imgSrc: logo6 },
  { name: "Indus", imgSrc: logo7 }, // Increase size
  { name: "DMart", imgSrc: logo8 },
  { name: "Tech Mahindra", imgSrc: logo9 },
  { name: "Uflex", imgSrc: logo10 }, // Increase size
  { name: "Kama", imgSrc: logo11 }, // Increase size
  { name: "Tata Capital", imgSrc: logo12 },
  { name: "Shriram", imgSrc: logo13 }, // Increase size
  { name: "Roha", imgSrc: logo14 }, // Increase size
  { name: "Guardian", imgSrc: logo15 }, // Increase size
  { name: "Teleperformance", imgSrc: logo16 }, // Increase size
  { name: "Beyondesign", imgSrc: logo17 }, // Increase size
  { name: "Religare", imgSrc: logo18 }, // Increase size
  { name: "PNB", imgSrc: logo19 },
  { name: "Vista", imgSrc: logo20 },
];

const ClientsSection = () => {
  return (
    <section className="bg-customY py-16 font-poppins">
      {/* Import Google Fonts for Archivo Black and Poppins */}
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
        `}
      </style>
      <div className="container mx-auto text-center px-6 md:px-20">
        <h2 className="font-archivo-black md:text-4xl text-2xl font-bold text-white mb-6">
          OUR CLIENTS
        </h2>
        <p className="text-white font-poppins text-xl font-bold mb-12">
          Companies we’ve partnered with to build great teams.
        </p>
        <div className="overflow-hidden">
          <div className="animate-marquee">
            <div className="flex items-center space-x-12">
              {clients.map((client, index) => (
                <div
                  key={index}
                  className={`flex items-center justify-center ${
                    [
                      "Tech Mahindra",
                      "Indus",
                      "Uflex",
                      "Kama",
                      "Shriram",
                      "Roha",
                      "Guardian",
                      "Teleperformance",
                      "Beyondesign",
                      "Religare",
                    ].includes(client.name)
                      ? "w-48 h-48 md:w-56 md:h-56" // Increase size for specific logos
                      : "w-24 h-24 md:w-32 md:h-32" // Default size for other logos
                  } p-4`}
                >
                  <img
                    src={client.imgSrc}
                    alt={client.name}
                    className="object-contain max-h-full max-w-full"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        @keyframes marquee {
          0% {
            transform: translateX(0%);
          }
          100% {
            transform: translateX(-100%);
          }
        }

        .animate-marquee {
          display: flex;
          width: 200%;
          animation: marquee 20s linear infinite;
        }

        @media (max-width: 768px) {
          .animate-marquee {
            width: 300%;
            animation: marquee 20s linear infinite;
          }
        }
      `}</style>
    </section>
  );
};

export default ClientsSection;
