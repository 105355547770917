import React from "react";
import iconDeliver from "../assets/icon1.PNG"; // Replace with your icon path
import iconCustomFit from "../assets/icon2.PNG"; // Replace with your icon path
import iconPoweredUp from "../assets/icon3.PNG"; // Replace with your icon path

const DifferentlySection = () => {
  return (
    <section className="bg-white text-center py-20 font-poppins">
      {/* Import Google Fonts for Archivo Black and Poppins */}
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
        `}
      </style>

      <div className="container mx-auto px-6 md:px-0">
        <h2 className="font-archivo-black text-customGray text-2xl md:text-3xl font-bold mb-16">
          PRECISION-DRIVEN TALENT ACQUISITION.
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div className="flex flex-col items-center">
            <img src={iconDeliver} alt="Recruiters That Deliver" className="w-20 h-20 mb-4" />
            <h3 className="font-archivo-black text-2xl md:text-xl font-bold mb-6 text-customGray">
              ACCOUNTABILITY
            </h3>
            <p className="font-poppins text-base md:text-lg text-customGray">
              Connecting premier talent with business needs, we deliver targeted recruitment solutions that drive growth across diverse industries.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img src={iconCustomFit} alt="Custom-Fit for YOU" className="w-20 h-20 mb-4" />
            <h3 className="font-archivo-black text-2xl md:text-xl font-bold mb-6 text-customGray">
              SPEED AND EFFICIENCY
            </h3>
            <p className="font-poppins text-base md:text-lg text-customGray">
              Delivering swift, efficient recruitment solutions, we achieve results within working 24 hours, ensuring seamless talent acquisition for immediate business needs.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img src={iconPoweredUp} alt="Powered-Up by Tech" className="w-20 h-20 mb-4" />
            <h3 className="font-archivo-black text-2xl md:text-xl font-bold mb-6 text-customGray">
              TRANSPARENCY
            </h3>
            <p className="font-poppins text-base md:text-lg text-customGray">
              Ensuring full transparency, we maintain clear communication throughout the recruitment process, fostering trust and delivering precise talent solutions.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DifferentlySection;
