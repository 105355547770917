import React from 'react';
import IndustryHero from '../assets/group-people-working-out-business-plan-office.jpg';

const IndustrialRecruiting = ({ scrollToHb9 }) => { // Receive the scrollToHb9 prop
  return (
    <>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
        `}
      </style>
      <div className="font-poppins flex flex-col lg:flex-row items-center py-14 px-10 lg:py-28 lg:px-20 justify-between bg-customGray text-white">
        <div className="flex flex-col items-start space-y-4 lg:space-y-6 w-full lg:w-1/2">
          <h1 className="font-archivo-black text-2xl lg:text-4xl xl:text-4xl font-bold leading-tight">
            INDUSTRY PRACTICES
          </h1>
          <p className="font-poppins text-md lg:text-xl xl:text-2xl tracking-wider">
            We recognize that each industry has its own nuances in terms of management style, hiring practices, and compensation norms. In order to provide clients with specialized recruiting solutions, Onboard HRServices has developed expertise in the following industry segments:
          </p>
          <button 
            className="bg-customY font-bold text-white px-6 py-3 hover:bg-orange-600 transition"
            onClick={scrollToHb9} // Use the scrollToHb9 function when clicked
          >
            LET'S CONNECT
          </button>
        </div>
        <div className="relative w-full lg:w-auto lg:ml-10 mt-10 lg:mt-0">
          <img
            src={IndustryHero}
            alt="Industry Hero"
            className="mx-auto w-full h-auto max-w-xs md:max-w-md lg:max-w-sm xl:max-w-lg lg:h-[300px] xl:h-[350px]" 
          />
        </div>
      </div>
    </>
  );
};

export default IndustrialRecruiting;
