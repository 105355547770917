import React from 'react';
import image1 from '../assets/corporate.webp'; // Update with your correct image paths
import image2 from '../assets/bg2.png';
import image3 from '../assets/bg7.jpg';

const ThreeImageSection = () => {
  return (
    <>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:wght@400;700&family=Poppins:wght@300;400;500;600&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-archivo {
            font-family: 'Archivo', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
        `}
      </style>
      <section className="w-full overflow-hidden font-poppins">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-0">
          
          {/* First Image and Text */}
          <div className="relative w-full h-[300px] md:h-[600px] flex items-end"> {/* Adjusted height for mobile view */}
            <img src={image1} alt="Hiring Broken" className="w-full h-full object-cover" />
            <div className="absolute inset-0 bg-black bg-opacity-60"></div>
            <div className="absolute inset-x-0 bottom-0 flex flex-col justify-end px-4 md:px-8 py-4 md:py-8">
              <div className="min-h-[160px] flex flex-col justify-end">
                <h2 className="font-archivo-black text-white text-2xl md:text-4xl mb-1 md:mb-2">HIRING <s>IS</s> WAS BROKEN</h2>
                <h3 className="font-archivo-black text-orange-500 text-lg md:text-4xl font-semibold mb-2 md:mb-4">[WE FIXED IT]</h3>
                <h3 className="font-archivo-black text-white text-lg md:text-2xl font-semibold mb-2 md:mb-4">TAILORED RECRUITMENT SOLUTIONS</h3>
                <p className="font-poppins text-white text-xs md:text-lg">
                  We provide highly customized talent acquisition strategies that align with your unique business objectives, ensuring a perfect fit for every role.
                </p>
              </div>
            </div>
          </div>

          {/* Second Image and Text */}
          <div className="relative w-full h-[300px] md:h-[600px] flex items-end"> {/* Adjusted height for mobile view */}
            <img src={image2} alt="Expert Recruiters" className="w-full h-full object-cover" />
            <div className="absolute inset-0 bg-black bg-opacity-60"></div>
            <div className="absolute inset-x-0 bottom-0 flex flex-col justify-end px-4 md:px-8 py-4 md:py-8">
              <div className="min-h-[160px] flex flex-col justify-end">
                <h3 className="font-archivo-black text-white text-lg md:text-2xl font-semibold mb-2 md:mb-4">EXPERTISE ACROSS INDUSTRIES</h3>
                <p className="font-poppins text-white text-xs md:text-lg">
                  With broad experience across various sectors, we connect you with top professionals who bring excellence and innovation to your industry.
                </p>
              </div>
            </div>
          </div>

          {/* Third Image and Text */}
          <div className="relative w-full h-[300px] md:h-[600px] flex items-end"> {/* Adjusted height for mobile view */}
            <img src={image3} alt="Better Tech" className="w-full h-full object-cover" />
            <div className="absolute inset-0 bg-black bg-opacity-60"></div>
            <div className="absolute inset-x-0 bottom-0 flex flex-col justify-end px-4 md:px-8 py-4 md:py-8">
              <div className="min-h-[160px] flex flex-col justify-end">
                <h3 className="font-archivo-black text-white text-lg md:text-2xl font-semibold mb-2 md:mb-4">AGILITY AND EXPERTISE</h3>
                <p className="font-poppins text-white text-xs md:text-lg">
                  Our team uses advanced strategies to streamline hiring, delivering top talent quickly and accurately, minimizing time-to-hire while maximizing business impact.
                </p>
              </div>
            </div>
          </div>
          
        </div>
      </section>
    </>
  );
};

export default ThreeImageSection;
