import React from 'react';

const ContactSection = () => {
  return (
    <section className="bg-white text-black py-12 flex items-center justify-center font-poppins px-4 md:px-0">
      {/* Import Google Fonts for Archivo Black and Poppins */}
      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
        .font-archivo-black {
          font-family: 'Archivo Black', sans-serif;
        }
        .font-poppins {
          font-family: 'Poppins', sans-serif;
        }
      `}</style>
      
      {/* Centered Form */}
      <div className="w-full max-w-2xl bg-white p-6 md:p-12 shadow-lg rounded-lg">
        <h2 className="font-archivo-black text-2xl md:text-3xl text-customGray font-bold mb-6 text-center">
          DROP US A LINE
        </h2>
        <p className="font-poppins text-lg md:text-xl text-customGray mb-6 text-center">
          Need help with hiring? Contact us below. We look forward to hearing from you!
        </p>
        <form className="space-y-4">
          <div className="flex flex-col md:flex-row md:space-x-4">
            <input 
              type="text" 
              placeholder="First name*" 
              className="w-full p-3 bg-gray-200 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <input 
              type="text" 
              placeholder="Last name*" 
              className="w-full p-3 bg-gray-200 border border-gray-300 rounded-full text-sm mt-4 md:mt-0 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <input 
            type="email" 
            placeholder="Email*" 
            className="w-full p-3 bg-gray-200 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
          />
          <input 
            type="text" 
            placeholder="Company name*" 
            className="w-full p-3 bg-gray-200 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
          />
          <textarea 
            placeholder="How can we help you hire?*" 
            className="w-full p-3 bg-gray-200 border border-gray-300 rounded-lg text-sm h-24 focus:outline-none focus:ring-2 focus:ring-orange-500"
          ></textarea>
          <textarea 
            placeholder="How did you hear about us?*" 
            className="w-full p-3 bg-gray-200 border border-gray-300 rounded-lg text-sm h-24 focus:outline-none focus:ring-2 focus:ring-orange-500"
          ></textarea>
          
          <button 
            type="submit" 
            className="w-full bg-orange-600 text-white p-3 rounded-full font-bold text-sm hover:bg-orange-500 transition duration-300 focus:outline-none focus:ring-2 focus:ring-orange-500"
          >
            Submit
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactSection;
