import React, { useRef } from 'react';
import Header from '../components/Header';
import IndustrialRecruiting from '../components/IndustrialRecruiting';
import SectorsList from '../components/Industry_practices';
import StatsSection from '../components/StatsSection';
import Notdiff from '../components/notdiff';
import Hb9 from '../components/Hb9';
import Footer from '../components/Footer';

const Industry = () => {
  const hb9Ref = useRef(null); // Create a reference for the Hb9 component

  const scrollToHb9 = () => {
    hb9Ref.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to the Hb9 component smoothly
  };

  return (
    <>
      <Header />
      
      {/* Pass the scroll function as a prop to the IndustrialRecruiting component */}
      <IndustrialRecruiting scrollToHb9={scrollToHb9} />

      <SectorsList />

      <div className="relative">
        <Notdiff />
      </div>
      
      <StatsSection />

      {/* Attach the reference to the Hb9 component */}
      <div ref={hb9Ref}>
        <Hb9 />
      </div>
      
      <Footer />
    </>
  );
};

export default Industry;
