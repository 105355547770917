import React from 'react';
import { FaPhoneAlt } from 'react-icons/fa';

const ContactUsSection = () => {
  return (
    <section className="bg-customGray text-white py-12 flex items-center justify-center font-poppins"> {/* Apply Poppins font to the entire section */}
      {/* Import Google Fonts for Archivo Black and Poppins */}
      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
        .font-archivo-black {
          font-family: 'Archivo Black', sans-serif;
        }
        .font-poppins {
          font-family: 'Poppins', sans-serif;
        }
      `}</style>
      
      <div className="max-w-screen-lg mx-auto flex flex-col md:flex-row items-start md:items-center text-left px-6 md:px-8 lg:px-24">
        {/* Icon */}
        <div className="text-6xl md:text-7xl lg:text-8xl mb-6 md:mb-0 md:mr-20 flex-shrink-0">
          <FaPhoneAlt />
        </div>
        {/* Text */}
        <div className="max-w-md">
          <h2 className="font-archivo-black text-2xl md:text-4xl lg:text-4xl font-semibold mb-7">CONTACT US</h2>
          <p className="font-poppins text-lg md:text-xl lg:text-xl leading-relaxed">
            Have a question? Want to connect with one of our businesses? We’ve got you covered. Connect with us directly using the below form or any of the below information.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;
