import React from "react";
import backgroundImage from "../assets/crew3.jpg"; // Add your background image

const AviationInspirationSection = () => {
  return (
    <section
      className="relative w-full h-[75vh] flex items-center justify-center text-center bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Text Content */}
      <div className="relative z-10 max-w-7xl mx-auto px-6">
        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-6">
          {/* Quote Box 1 */}
          <div className="bg-white bg-opacity-80 p-6 rounded-lg shadow-md max-w-xs md:max-w-sm transform transition-transform duration-300 hover:scale-105">
            <h3 className="text-md md:text-xl font-archivo-black text-black mb-2">
              Breaking the mold, pioneering the future of aviation with sky-high ambition
            </h3>
          </div>
          
          {/* Quote Box 2 */}
          <div className="bg-white bg-opacity-80 p-6 rounded-lg shadow-md max-w-xs md:max-w-sm transform transition-transform duration-300 hover:scale-105">
            <h3 className="text-md md:text-xl font-archivo-black text-black mb-2">
              Empowering the world to spread its wings, one flight at a time.
            </h3>
          </div>
          
          {/* Quote Box 3 */}
          <div className="bg-white bg-opacity-80 p-6 rounded-lg shadow-md max-w-xs md:max-w-sm transform transition-transform duration-300 hover:scale-105">
            <h3 className="text-md md:text-xl font-archivo-black text-black mb-2">
              Where passion takes flight and ambition knows no ceiling.
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AviationInspirationSection;
