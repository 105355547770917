import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { FaCheckCircle } from "react-icons/fa"; // Importing icons for bullet points
import backgroundImg1 from "../assets/crew4.JPG";
import backgroundImg2 from "../assets/portrait-flight-attendant-with-safety-belt.jpg";
import backgroundImg3 from "../assets/img1.jpeg";
import backgroundImg4 from "../assets/air2.jpg";

const AviationStaffingSlider = () => {
  return (
    <>
      {/* Importing Google Fonts for styling */}
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@300;400;500;600&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
          .swiper-pagination-bullet {
            background: #ecb821; /* Custom color for pagination dots */
          }
        `}
      </style>

      <section className="relative w-full h-screen">
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000 }}
          className="w-full h-full"
        >
          {/* Slide 1 - Cabin Crew & Operations */}
          <SwiperSlide>
            <div
              className="relative w-full h-full bg-cover bg-center flex items-center justify-center"
              style={{ backgroundImage: `url(${backgroundImg4})` }}
            >
              {/* Gradient Overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-black opacity-70"></div>

              {/* Text Content */}
              <div className="text-center md:text-center px-4 md:px-12 max-w-4xl mx-auto relative z-10 animate-fadeIn">
                <h2 className="text-xl md:text-4xl text-white font-archivo-black mb-10 shadow-text transition-transform duration-500 ease-in-out transform hover:scale-105">
                  EXPERT SOLUTIONS FOR AVIATION STAFFING
                </h2>
                {/* Text Overlay Container */}
                <div className="bg-black bg-opacity-50 p-4 rounded-lg">
                  <p className="text-md md:text-xl text-white font-poppins mb-6 shadow-text animate-slideIn">
                    With over a decade of expertise in aviation recruitment, Onboard HRServices has successfully sourced and placed talent worldwide across various roles. Our strategic approach and deep industry knowledge ensure we connect the right professionals to critical positions, supporting the operational excellence of global aviation businesses.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {/* Slide 2 - Talent We've Placed (Part 1) */}
          <SwiperSlide>
            <div
              className="relative w-full h-full bg-cover bg-center flex items-center justify-center"
              style={{ backgroundImage: `url(${backgroundImg1})` }}
            >
              {/* Gradient Overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-black opacity-70"></div>

              {/* Text Content */}
              <div className="text-left md:text-center px-4 md:px-12 max-w-4xl mx-auto relative z-10">
                <h3 className="text-2xl md:text-4xl text-white font-archivo-black mb-4 shadow-text">
                  TALENT WE’VE PLACED
                </h3>
                {/* Overlay on bullet points */}
                <div className="relative bg-black bg-opacity-30 rounded-lg p-6 animate-slideIn">
                  <ul className="list-none text-base md:text-lg text-white font-poppins space-y-4 relative z-10">
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" /> {/* Checkmark Icon */}
                      <span>Cabin CREW (All Levels) – PAN INDIA</span>
                    </li>
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" />
                      <span>Crew Performance</span>
                    </li>
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" />
                      <span>Flight Report Analyst</span>
                    </li>
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" />
                      <span>
                        Trainers – SEP/ DGR Instructor, AVSEC
                      </span>
                    </li>
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" />
                      <span>Base Manager, Airport Manager </span>
                    </li>
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" />
                      <span>IFS Service Design & Product Specialist</span>
                    </li>
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" />
                      <span>Asst Control Officer</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {/* Slide 3 - Crew & Safety Management */}
          <SwiperSlide>
            <div
              className="relative w-full h-full bg-cover bg-center flex items-center justify-center"
              style={{ backgroundImage: `url(${backgroundImg2})` }}
            >
              {/* Gradient Overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-black opacity-70"></div>

              {/* Text Content */}
              <div className="text-left md:text-center px-4 md:px-12 max-w-4xl mx-auto relative z-10">
                <h3 className="text-2xl md:text-4xl text-white font-archivo-black mb-4 shadow-text">
                  TALENT WE’VE PLACED
                </h3>
                {/* Overlay on bullet points */}
                <div className="relative bg-black bg-opacity-30 rounded-lg p-6 animate-slideIn">
                  <ul className="list-none text-base md:text-lg text-white font-poppins space-y-4 relative z-10">
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" />
                      <span>CREW –  SCHEDULING / CABIN SAFETY</span>
                    </li>
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" />
                      <span>Airports Operations & Customer Service</span>
                    </li>
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" />
                      <span>Flight Dispatcher</span>
                    </li>
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" />
                      <span>Fleet Technical Support</span>
                    </li>
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" />
                      <span>Training & Licensing</span>
                    </li>
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" />
                      <span>Technical Auditor – FSSAI & Hygiene</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </SwiperSlide>
          
          {/* Slide 4 - Additional Placements */}
          <SwiperSlide>
            <div
              className="relative w-full h-full bg-cover bg-center flex items-center justify-center"
              style={{ backgroundImage: `url(${backgroundImg3})` }}
            >
              {/* Gradient Overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-black opacity-70"></div>

              {/* Text Content */}
              <div className="text-left md:text-center px-4 md:px-12 max-w-4xl mx-auto relative z-10">
                <h3 className="text-2xl md:text-4xl text-white font-archivo-black mb-6 shadow-text">
                  TALENT WE’VE PLACED
                </h3>
                {/* Overlay on bullet points */}
                <div className="relative bg-black bg-opacity-30 rounded-lg p-6 animate-slideIn">
                  <ul className="list-none text-base md:text-lg text-white font-poppins space-y-4 relative z-10">
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" />
                      <span>International Sales & Marketing</span>
                    </li>
                    <li className="flex items-center space-x-2 md:space-x-4 p-2 md:px-4 rounded hover:bg-white hover:bg-opacity-20 transition duration-300">
                      <FaCheckCircle className="text-yellow-500 md:mr-2" />
                      <span>Flight Operations</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </SwiperSlide>  
        </Swiper>
      </section>
    </>
  );
};

export default AviationStaffingSlider;
