import React from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactUsSection from '../components/ContactUsSection';
import ContactSection from '../components/ContacUs';
import OfficesSection from '../components/OfficeSection';

function Contact() {
    return (
      <div>
        <Header />
       <ContactUsSection />
       <ContactSection />
       <OfficesSection />
   

    
        <Footer />
        {/* Add more components or content here as needed */}
      </div>
    );
  }
  
  export default Contact;