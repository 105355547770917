import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BannerSection from "../components/Banner";
import TabSection from "../components/Banner2";
import PartnersSection from "../components/Banner3";
import AviationInspirationSection from "../components/Banner4";

function Aviation() {
  return (
    <div>
      <Header />
      <BannerSection />
      <AviationInspirationSection/>
      <TabSection/>
      <PartnersSection/>

      <Footer />
      {/* Add more components or content here as needed */}
    </div>
  );
}

export default Aviation;
