import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import AboutUs from "./pages/AboutUs";
import Industry from "./pages/Industry";
import Aviation from "./pages/Aviation"; // Use exact case

function App() {
  return (
    <Router>
      <Routes>
        {/* Set Home component as the default route */}
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/industry" element={<Industry />} />
        <Route path="/aviation" element={<Aviation />} /> 
        {/* Add more routes for additional pages */}
      </Routes>
    </Router>
  );
}

export default App;
