// import React, { useEffect, useRef, useState } from 'react';

// const StatisticsSection = () => {
//   const [isVisible, setIsVisible] = useState(false);
//   const sectionRef = useRef(null);

//   useEffect(() => {
//     // Create an IntersectionObserver instance
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         // Check if the section is in view
//         if (entry.isIntersecting) {
//           setIsVisible(true);
//         } else {
//           setIsVisible(false);
//         }
//       },
//       { threshold: 0.1 } // Trigger when 10% of the section is visible
//     );

//     if (sectionRef.current) {
//       observer.observe(sectionRef.current);
//     }

//     // Cleanup the observer on component unmount
//     return () => {
//       if (sectionRef.current) {
//         observer.unobserve(sectionRef.current);
//       }
//     };
//   }, []);

//   return (
//     <div
//       ref={sectionRef}
//       className={`py-16 md:py-24 font-poppins transition-colors duration-500 ${
//         isVisible ? 'bg-white' : 'bg-gray-200'
//       }`}
//     >
//       {/* Import Google Fonts for Archivo Black and Poppins */}
//       <style>
//         {`
//           @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
//           .font-archivo-black {
//             font-family: 'Archivo Black', sans-serif;
//           }
//           .font-poppins {
//             font-family: 'Poppins', sans-serif;
//           }
//           .stat-circle {
//             transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover */
//             cursor: pointer; /* Change cursor to indicate interactivity */
//           }
//           .stat-circle:hover {
//             transform: scale(1.1); /* Scale up the circle on hover */
//             box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
//           }
//         `}
//       </style>
//       <div className="text-center text-customGray mb-4 md:mb-10 px-4 sm:px-0">
//         <h2 className="font-archivo-black text-lg md:text-xl lg:text-2xl ">
//           We've hit milestones that make a difference—numbers you can count on.
//         </h2>
//       </div>
//       <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 px-4 sm:px-10">
//         <div className="text-center">
//           <div className="bg-customY text-white rounded-full w-24 h-24 md:w-32 md:h-32 lg:w-48 lg:h-48 xl:w-56 xl:h-56 flex items-center justify-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mx-auto stat-circle">
//             50+
//           </div>
//           <p className="font-poppins text-customGray mt-2 text-sm md:text-lg lg:text-xl text-black font-semibold">
//             CLIENTS
//           </p>
//         </div>
//         <div className="text-center">
//           <div className="bg-customY text-white rounded-full w-24 h-24 md:w-32 md:h-32 lg:w-48 lg:h-48 xl:w-56 xl:h-56 flex items-center justify-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mx-auto stat-circle">
//             5LACS+
//           </div>
//           <p className="font-poppins text-customGray mt-2 text-sm md:text-lg lg:text-xl text-black font-semibold">
//             CANDIDATE DATABASE
//           </p>
//         </div>
//         <div className="text-center">
//           <div className="bg-customY text-white rounded-full w-24 h-24 md:w-32 md:h-32 lg:w-48 lg:h-48 xl:w-56 xl:h-56 flex items-center justify-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mx-auto stat-circle">
//             12+
//           </div>
//           <p className="font-poppins text-customGray mt-2 text-sm md:text-lg lg:text-xl text-black font-semibold">
//             INDUSTRY PRACTICES
//           </p>
//         </div>
//         <div className="text-center">
//           <div className="bg-customY text-white rounded-full w-24 h-24 md:w-32 md:h-32 lg:w-48 lg:h-48 xl:w-56 xl:h-56 flex items-center justify-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mx-auto stat-circle">
//             10K+
//           </div>
//           <p className="font-poppins text-customGray mt-2 text-sm md:text-lg lg:text-xl text-black font-semibold">
//             PLACEMENT ACROSS INDUSTRIES
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StatisticsSection;


import React, { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';

const StatisticsSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    // Create an IntersectionObserver instance
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Check if the section is in view
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the section is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className={`py-16 md:py-24 font-poppins transition-colors duration-500 ${
        isVisible ? 'bg-white' : 'bg-gray-200'
      }`}
    >
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;500;600;700&display=swap');
          .font-archivo-black {
            font-family: 'Archivo Black', sans-serif;
          }
          .font-poppins {
            font-family: 'Poppins', sans-serif;
          }
          .stat-circle {
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            cursor: pointer;
          }
          .stat-circle:hover {
            transform: scale(1.1);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
          }
        `}
      </style>
      <div className="text-center text-customGray mb-4 md:mb-10 px-4 sm:px-0">
        <h2 className="font-archivo-black text-lg md:text-xl lg:text-2xl">
          We've hit milestones that make a difference—numbers you can count on.
        </h2>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 px-4 sm:px-10">
        <div className="text-center">
          <div className="bg-customY text-white rounded-full w-24 h-24 md:w-32 md:h-32 lg:w-48 lg:h-48 xl:w-56 xl:h-56 flex items-center justify-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mx-auto stat-circle">
            {isVisible && <CountUp end={50} duration={2} />}+
          </div>
          <p className="font-poppins text-customGray mt-2 text-sm md:text-lg lg:text-xl text-black font-semibold">
            CLIENTS
          </p>
        </div>
        <div className="text-center">
          <div className="bg-customY text-white rounded-full w-24 h-24 md:w-32 md:h-32 lg:w-48 lg:h-48 xl:w-56 xl:h-56 flex items-center justify-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mx-auto stat-circle">
            {isVisible && <CountUp end={500000} duration={3} />}+
          </div>
          <p className="font-poppins text-customGray mt-2 text-sm md:text-lg lg:text-xl text-black font-semibold">
            CANDIDATE DATABASE
          </p>
        </div>
        <div className="text-center">
          <div className="bg-customY text-white rounded-full w-24 h-24 md:w-32 md:h-32 lg:w-48 lg:h-48 xl:w-56 xl:h-56 flex items-center justify-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mx-auto stat-circle">
            {isVisible && <CountUp end={12} duration={2} />}+
          </div>
          <p className="font-poppins text-customGray mt-2 text-sm md:text-lg lg:text-xl text-black font-semibold">
            INDUSTRY PRACTICES
          </p>
        </div>
        <div className="text-center">
          <div className="bg-customY text-white rounded-full w-24 h-24 md:w-32 md:h-32 lg:w-48 lg:h-48 xl:w-56 xl:h-56 flex items-center justify-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mx-auto stat-circle">
            {isVisible && <CountUp end={10000} duration={3} />}+
          </div>
          <p className="font-poppins text-customGray mt-2 text-sm md:text-lg lg:text-xl text-black font-semibold">
            PLACEMENT ACROSS INDUSTRIES
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatisticsSection;